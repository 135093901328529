export default {
  computed: {
    sensors () {
      const originSensors = this.device.data.sensors
      const type = this.device.settings.sensor_type
      const sensors = []
      if (Object.keys(originSensors).length) {
        for (const sensorType in originSensors) {
          let sensor = null
          if (sensorType === 'temperatures' && type >= 1 && type <= 6) {
            sensor = {
              origin_type: sensorType,
              type: sensorType,
              origin_value: originSensors[sensorType],
              value: originSensors[sensorType],
              color: 'light-blue darken-1',
              text_color: 'light-blue--text',
              main_icon: 'mdi-thermometer',
              value_icon: 'mdi-temperature-celsius',
            }
          }
          if (sensorType === 'humidity' && type >= 2 && type <= 6) {
            sensor = {
              origin_type: sensorType,
              type: sensorType,
              origin_value: originSensors[sensorType],
              value: originSensors[sensorType],
              color: 'cyan darken-1',
              text_color: 'cyan--text',
              main_icon: 'mdi-water-percent',
              value_icon: 'mdi-percent-outline',
            }
          }
          if (sensorType === 'light' && type >= 6 && type <= 7) {
            sensor = {
              origin_type: sensorType,
              type: sensorType,
              origin_value: originSensors[sensorType],
              value: originSensors[sensorType],
              color: 'yellow darken-3',
              text_color: 'yellow--text text--darken-3',
              main_icon: 'mdi-lightbulb-on',
              value_icon: 'lx',
            }
          }
          if (sensorType === 'detection' && type == 8) {
            sensor = {
              origin_type: sensorType,
              type: sensorType,
              origin_value: originSensors[sensorType],
              value: originSensors[sensorType] ? 'מזהה תנועה' : 'אין תנועה',
              color: 'deep-orange lighten-1',
              text_color: 'deep-orange--text text--lighten-1',
              main_icon: 'mdi-motion-sensor',
              value_icon: null,
            }
          }
          if (sensorType === 'smoke' && type == 9) {
            sensor = {
              origin_type: sensorType,
              type: sensorType,
              origin_value: originSensors[sensorType],
              value: originSensors[sensorType] ? 'מזהה עשן!' : 'תקין',
              color: originSensors[sensorType]
                ? 'red accent-4'
                : 'blue-grey lighten-2',
              text_color: originSensors[sensorType]
                ? 'red--text text--accent-4'
                : 'blue-grey--text text--lighten-2',
              main_icon: 'mdi-smoke-detector',
              value_icon: null,
            }
          }
          if (['water', 'dry_contact'].includes(sensorType) && type == 10) {
            sensor = {
              origin_type: sensorType,
              type: 'water',
              origin_value: originSensors[sensorType],
              value: originSensors[sensorType] ? 'מזהה מים!' : 'תקין',
              color: 'blue darken-2',
              text_color: 'blue--text text--darken-2',
              main_icon:
                'mdi-water' + (originSensors[sensorType] ? '' : '-outline'),
              value_icon: null,
            }
          }
          if (sensorType === 'hall') {
            if (type == 11) {
              sensor = {
                origin_type: 'hall',
                type: 'hall',
                origin_value: originSensors[sensorType],
                value: originSensors[sensorType] ? 'מזהה מגנט' : 'לא מזהה מגנט',
                color: 'red lighten-1',
                text_color: 'red--text text--lighten-1',
                main_icon: null,
                value_icon: originSensors[sensorType]
                  ? 'mdi-magnet-on'
                  : 'mdi-magnet',
              }
            }
            if (type == 12) {
              sensor = {
                origin_type: 'hall',
                type: 'door',
                origin_value: originSensors[sensorType],
                value: originSensors[sensorType] ? 'הדלת סגורה' : 'הדלת פתוחה',
                color: 'deep-purple',
                text_color: 'deep-purple--text',
                main_icon: null,
                value_icon: originSensors[sensorType]
                  ? 'mdi-door'
                  : 'mdi-door-open',
              }
            }
            if (type == 13) {
              sensor = {
                origin_type: 'hall',
                type: 'window',
                origin_value: originSensors[sensorType],
                value: originSensors[sensorType] ? 'החלון סגור' : 'החלון פתוח',
                color: 'deep-purple',
                text_color: 'deep-purple--text',
                main_icon: null,
                value_icon: originSensors[sensorType]
                  ? 'mdi-window-closed-variant'
                  : 'mdi-window-open-variant',
              }
            }
          }
          if (sensorType === 'tamper') {
            sensor = {
              origin_type: 'tamper',
              type: 'tamper',
              origin_value: originSensors[sensorType],
              value: originSensors[sensorType] ? 'אזהרה!' : 'תקין',
              color: originSensors[sensorType]
                ? 'red accent-4'
                : 'orange darken-2',
              text_color: originSensors[sensorType]
                ? 'red--text text--accent-4'
                : 'orange--text text--darken-2',
              main_icon: 'mdi-alert-box',
              value_icon: null,
            }
          }
          if (sensor) {
            sensors.push(sensor)
          }
        }
      }
      // console.log('end - sensors', sensors)
      return sensors
    },
  },

  // methods: {
  // setBattery (data) {
  //
  // },
  // },
}
