<template>
  <div class="d-flex justify-center">
    <v-row justify="space-around" class="py-4 maxWidth300">
      <v-btn
        fab
        color="red"
        :dark="!loading"
        elevation="3"
        :outlined="value !== 2"
        :disabled="loading && value !== 2"
        :loading="loading && value === 2"
        @click="value = 2"
      >
        <v-icon>mdi-flash-off mdi-36px</v-icon>
      </v-btn>
      <v-btn
        fab
        color="blue"
        :dark="!loading"
        elevation="3"
        :outlined="value !== 0"
        :disabled="loading && value !== 0"
        :loading="loading && value === 0"
        @click="value = 0"
      >
        <v-icon>mdi-flash-auto mdi-36px</v-icon>
      </v-btn>
      <v-btn
        fab
        color="green"
        :dark="!loading"
        elevation="3"
        :outlined="value !== 1"
        :disabled="loading && value !== 1"
        :loading="loading && value === 1"
        @click="value = 1"
      >
        <v-icon>mdi-flash mdi-36px</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'DeviceDevmode',
  props: {
    model: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    value: null,
  }),

  watch: {
    model (value) {
      this.value = value
    },
    value (value) {
      this.$emit('edit', value)
    },
  },

  mounted () {
    this.value = this.model
  },
}
</script>
<style scoped>
.maxWidth300 {
  max-width: 300px;
}
</style>
