<template>
  <div class="">
    <h5>מצב פעולה</h5>
    <v-select
      v-model="value"
      :loading="loading"
      :disabled="loading"
      :items="available"
      label="מצב פעולה"
      :messages="loading ? 'מעדכן מכשיר...' : ''"
      hide-details
      solo
    />
  </div>
</template>

<script>
export default {
  name: 'DeviceDevmode',
  props: {
    model: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    value: null,
    // edit: null,
    devmodes: [
      { text: 'טיימר', value: 0 },
      { text: 'חימום', value: 1 },
      { text: 'קירור', value: 2 },
      { text: 'הגברת לחות', value: 3 },
      { text: 'הורדת לחות', value: 4 },
      { text: 'הגברת אור', value: 5 },
      { text: 'הורדת אור', value: 6 },
      { text: 'טמפרטורה - סף עליון ותחתון', value: 7 },
    ],
  }),

  computed: {
    device () {
      const device = this.$store.getters['devices/getBySlug'](
        this.$route.params.slug,
      )
      return device
    },
    devmode () {
      return this.device.data.devmode
    },
    available () {
      const SENSOR = this.device.settings.sensor_type
      const TopBottom = !!(
        this.device.settings.role == 0 && this.device.data.battery
      )
      const available = []
      if (this.role) available.push(this.devmodes[0])
      if (SENSOR >= 1 && SENSOR <= 6) {
        available.push(this.devmodes[1])
        available.push(this.devmodes[2])
        if (TopBottom) available.push(this.devmodes[7])
      }
      if (SENSOR >= 2 && SENSOR <= 6) {
        available.push(this.devmodes[3])
        available.push(this.devmodes[4])
      }
      if (SENSOR >= 6 && SENSOR <= 7) {
        available.push(this.devmodes[5])
        available.push(this.devmodes[6])
      }
      return available
    },
  },

  watch: {
    model (value) {
      this.value = value
    },
    value (value) {
      this.$emit('edit', value)
    },
  },

  mounted () {
    this.value = this.model
  },
  // watch: {
  //   edit (devmode) {
  //     this.submit()
  //   },
  // },

  // mounted () {
  //   this.setEditValue()
  // },
  // methods: {
  //   setEditValue () {
  //     this.edit = this.devmode
  //   },
  // submit () {
  //   if (this.edit !== this.devmode) {
  //     console.log(
  //       'submit new devmode: ',
  //       this.edit,
  //       this.devmodes.find(d => d.value === this.edit).text,
  //     )
  //     console.log('start loading')
  //     this.loading = true
  //     setTimeout(() => {
  //       console.log('stop loading')
  //       this.loading = false
  //     }, 5000)
  //   }
  // },
  // },
}
</script>
