<template>
  <v-row v-if="sensors.length" class="flex-column">
    <v-divider />
    <v-col cols="12" class="py-8 px-4">
      <v-row justify="space-around">
        <v-col v-for="sensor in sensors" :key="sensor.type">
          <div class="text-center display-2">
            <div :class="sensor.text_color">
              <v-icon :color="sensor.color" class="me-2">
                {{ sensor.main_icon }} mdi-24px
              </v-icon>
              <strong>{{ $phraser.set(sensor.type) }}: </strong>
              <span v-if="!Array.isArray(sensor.value)" class="px-1">
                <v-icon :color="sensor.color" class="">
                  {{ sensor.value_icon }}
                </v-icon>
                {{ sensor.value }}
              </span>
              <span v-for="(s, i) in sensor.value" v-else :key="i" class="px-1">
                <v-icon :color="sensor.color" class="">
                  {{ sensor.value_icon }}
                </v-icon>
                {{ s }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-divider />
  </v-row>
</template>

<script>
export default {
  name: 'SensorsBar',

  props: {
    sensors: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
