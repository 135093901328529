<template>
  <v-container v-if="device" fluid tag="section">
    <sensors-bar :sensors="sensors" />

    <v-row justify="space-around">
      <v-col class="minWidth500" cols="12" md="4">
        <v-card class="pa-4 grey lighten-4">
          <v-card-title class="display-2">
            פעולה {{ device.settings.role == 0 ? 'להתראות' : 'לבקר' }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row v-if="device.settings.role == 1">
              <v-col cols="12">
                <mode
                  :model="actionForm.mode"
                  :loading="loadingActions"
                  @edit="v => (actionForm.mode = v)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <devmode
                  :model="actionForm.devmode"
                  :loading="loadingActions"
                  @edit="v => (actionForm.devmode = v)"
                />
              </v-col>
            </v-row>
            <v-row v-if="device.data.devmode">
              <v-col cols="12">
                <thresholds
                  :devmode="actionForm.devmode"
                  :threshold="actionForm.threshold"
                  :top_threshold="actionForm.top_threshold"
                  :bottom_threshold="actionForm.bottom_threshold"
                  :loading="loadingActions"
                  @edit="editThresholds"
                />
              </v-col>
              <v-col
                v-if="
                  device.settings.role == 1 &&
                    [10, 14].includes(device.settings.sensor_type)
                "
                cols="12"
                class="mb-10 my-n6 py-0 orange--text text--darken-3"
              >
                <strong>הסף לבקר המוגדר כמגע יבש / חיישן הצפה הינו 205</strong>
              </v-col>
            </v-row>
            <v-row
              justify="space-between"
              class="align-center success--text text--darken-3"
            >
              <v-btn
                class="mt-2"
                color="secondary"
                :loading="loadingActions"
                :disabled="!permitted"
                @click="submitActions"
              >
                שמירה
              </v-btn>

              <h4 v-show="ActionsModified">
                הגדרות הפעולה להתראות נערכו בהצלחה!
              </h4>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="minWidth500" cols="12" md="4">
        <v-card class="pa-4 grey lighten-4">
          <v-card-title class="display-2">
            הגדרות מכשיר
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row class="pt-3">
              <h3 class="mb-n2">
                תפקיד מכשיר
              </h3>
              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="form.role"
                  row
                  class="pt-0"
                  :disabled="loadingSettings"
                >
                  <v-radio v-if="form.sensor_type" label="חיישן" :value="0" />
                  <v-radio
                    v-if="!isNaN(device.data.output)"
                    class="ms-8"
                    label="בקר"
                    :value="1"
                  />
                  <v-radio
                    v-if="!form.sensor_type && device.data.battery"
                    class="ms-8"
                    label="מיקום"
                    :value="2"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <sensor-type
                  :loading="loadingSettings"
                  :model="form.sensor_type"
                  @edit="v => (form.sensor_type = v)"
                />
              </v-col>
            </v-row>
            <v-row v-show="form.sensor_type > 0 && form.sensor_type < 7">
              <v-col cols="12">
                <main-temp
                  :loading="loadingSettings"
                  :model="form.single_temp"
                  @edit="v => (form.single_temp = v)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <notify-email
                  :loading="loadingSettings"
                  :model="form.emails"
                  @edit="v => (form.emails = v)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <notify-push
                  :loading="loadingSettings"
                  :model="form.push"
                  @edit="v => (form.push = v)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <notify-sms
                  :loading="loadingSettings"
                  :model="form.sms"
                  @edit="v => (form.sms = v)"
                />
              </v-col>
            </v-row>
            <div
              class="d-flex justify-space-between flex-nowrap align-baseline mt-5"
            >
              <label
                class="me-5 d-flex flex-grow-1 flex-shrink-0"
                style="max-width:250px"
              >
                <v-icon color="red accent-4" class="me-2">
                  mdi-signal-off
                </v-icon>
                זמן להתראה לניתוק תקשורת (דקות):
              </label>
              <div class="d-flex flex-grow-0 flex-shrink-1">
                <v-btn icon color="blue" @click="form.ttl++">
                  <v-icon>mdi-plus mdi-24px</v-icon>
                </v-btn>
                <base-smart-input
                  :model.sync="form.ttl"
                  required
                  solo
                  :loading="loadingSettings"
                  type="tel"
                  autocomplete="off"
                  persistent-placeholder
                  background-color="white"
                />
              </div>
              <v-btn icon color="blue" @click="reduceTTL">
                <v-icon>mdi-minus mdi-24px</v-icon>
              </v-btn>
            </div>
            <div class="ms-7 mt-n8 mb-4">
              <small class="grey--text text--darken-1">
                ( ≈
                {{
                  Math.round(Math.round(form.ttl * 60) / device.settings.cycle)
                }}
                שידורים)
              </small>
            </div>
            <div
              v-if="form.role == 0 && form.sensor_type > 0"
              class="d-flex justify-space-between flex-nowrap align-baseline"
            >
              <label
                class="me-5 d-flex flex-grow-1 flex-shrink-0"
                style="max-width:250px"
              >
                <v-icon color="red accent-4" class="me-2">
                  mdi-snowflake-alert
                </v-icon>
                מספר שידורים שחורגים בסף להוצאת התראה:
              </label>
              <v-btn icon color="blue" @click="form.exceeds++">
                <v-icon>mdi-plus mdi-24px</v-icon>
              </v-btn>
              <div class="d-flex flex-grow-0 flex-shrink-1">
                <base-smart-input
                  :model.sync="form.exceeds"
                  required
                  solo
                  :loading="loadingSettings"
                  type="tel"
                  autocomplete="off"
                  persistent-placeholder
                  background-color="white"
                />
              </div>
              <v-btn icon color="blue" @click="reduceExceeds">
                <v-icon>mdi-minus mdi-24px</v-icon>
              </v-btn>
            </div>
            <div
              v-if="form.role == 0 && form.sensor_type > 0"
              class="ms-7 mt-n9 mb-4"
            >
              <small class="grey--text text--darken-1">
                ( ≈
                {{
                  parseFloat(
                    (form.exceeds * device.settings.cycle) / 60
                  ).toFixed(1)
                }}
                דקות)
              </small>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row
              justify="space-between"
              class="align-center success--text text--darken-3"
            >
              <v-btn
                color="secondary"
                :loading="loadingSettings"
                :disabled="!permitted"
                @click="submitSettings"
              >
                שמירת הגדרות
              </v-btn>
              <h4 v-show="SettingsModified">
                ההגדרות נערכו בהצלחה!
              </h4>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sensors from '@/mixins/sensors'
import SensorsBar from '@/views/dashboard/components/SensorsBar'
import Mode from '@/views/dashboard/pages/devices/settings/Mode'
import Devmode from '@/views/dashboard/pages/devices/settings/devmode'
import Thresholds from '@/views/dashboard/pages/devices/settings/thresholds'
import MainTemp from '@/views/dashboard/pages/devices/settings/MainTemp'
import NotifyEmail from '@/views/dashboard/pages/devices/settings/NotifyEmail'
import NotifyPush from '@/views/dashboard/pages/devices/settings/NotifyPush'
import NotifySms from '@/views/dashboard/pages/devices/settings/NotifySms'
import SensorType from '@/views/dashboard/pages/devices/settings/SensorType'
export default {
  name: 'DeviceSettings',
  components: {
    SensorsBar,
    Mode,
    Devmode,
    Thresholds,
    MainTemp,
    NotifyEmail,
    NotifyPush,
    NotifySms,
    SensorType,
  },
  mixins: [Sensors],

  data: () => ({
    actionForm: {},
    form: {},
    submitted: false,
    loadingActions: false,
    loadingSettings: false,
    SettingsModified: false,
    ActionsModified: false,
    roles: ['sensor', 'controller'],
  }),

  computed: {
    device () {
      const device = this.$store.getters['devices/getBySlug'](
        this.$route.params.slug,
      )
      return device
    },
    permitted () {
      return !!this.$store.getters['user/permissions'].edit_device
    },
    ttlMinimum () {
      return Math.ceil(this.device.settings.cycle / 60)
    },
    exceedsMinimum () {
      return Math.ceil(this.device.settings.cycle / 60)
    },
  },

  // watch: {
  //   form: {
  //     handler (form) {
  //       // console.log(form.role, form.sensor_type)
  //       // console.log(form.role == 0 && form.sensor_type > 0)
  //       // console.log(this.form)
  //     },
  //     deep: true,
  //   },
  // },

  mounted () {
    if (this.device) {
      this.setForm()
      // console.log(this.sensors)
    }
  },

  methods: {
    setForm () {
      this.actionForm = {
        mode: parseFloat(this.device.data.mode),
        devmode: parseFloat(this.device.data.devmode),
        threshold: parseFloat(this.device.data.threshold),
        top_threshold: parseFloat(this.device.data.top_threshold),
        bottom_threshold: parseFloat(this.device.data.bottom_threshold),
      }
      this.form = {
        role: parseInt(this.device.settings.role),
        single_temp: parseInt(this.device.settings.single_temp),
        emails: parseInt(this.device.settings.emails),
        push: parseInt(this.device.settings.push),
        sms: parseInt(this.device.settings.sms),
        sensor_type: parseInt(this.device.settings.sensor_type),
        ttl: parseInt(this.device.settings.ttl),
        exceeds: parseInt(this.device.settings.exceeds),
      }
    },
    editThresholds (form) {
      this.actionForm.threshold = form.threshold
      this.actionForm.top_threshold = form.top_threshold
      this.actionForm.bottom_threshold = form.bottom_threshold
    },
    reduceTTL () {
      if (this.form.ttl > this.ttlMinimum) {
        this.form.ttl--
      }
    },
    reduceExceeds () {
      if (this.form.exceeds > this.exceedsMinimum) {
        this.form.exceeds--
      }
    },
    async submitActions () {
      const device = {
        slug: this.$route.params.slug,
        identifier: this.device.identifier,
        data: {},
      }
      for (const param in this.actionForm) {
        if (!isNaN(this.actionForm[param])) {
          device.data[param] = this.actionForm[param]
        }
      }
      this.loadingActions = true
      await this.$store.dispatch('devices/editDevice', device)
      this.loadingActions = false
      this.ActionsModified = true
      setTimeout(() => {
        this.ActionsModified = false
      }, 5000)
    },
    async submitSettings () {
      const device = {
        slug: this.$route.params.slug,
        identifier: this.device.identifier,
        settings: {},
      }
      for (const param in this.form) {
        if (!isNaN(this.form[param])) {
          device.settings[param] = this.form[param]
        }
      }
      this.loadingSettings = true
      await this.$store.dispatch('devices/editDevice', device)
      this.loadingSettings = false
      this.SettingsModified = true
      setTimeout(() => {
        this.SettingsModified = false
      }, 5000)
    },
  },
}
</script>
<style scoped>
.minWidth500 {
  min-width: 500px;
}
</style>
