<template>
  <div class="d-flex flex-row align-baseline">
    <h4 class="me-3 text-no-wrap">
      סוג חיישנים
    </h4>
    <v-select
      v-model="value"
      :loading="loading"
      :items="types"
      label="סוג חיישנים"
      hide-details
      :disabled="!permitted"
      solo
    />
  </div>
</template>

<script>
export default {
  name: 'DeviceSensorType',
  props: {
    model: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    value: null,
    types: [
      { text: 'אין חיישנים', value: 0 },
      { text: 'טמפרטורה', value: 1 },
      { text: 'טמפרטורה ולחות', value: 2 },
      { text: 'טמפרטורה ולחות אדמה', value: 3 },
      { text: 'טמפרטורה, לחות ואיכות אוויר', value: 4 },
      { text: 'טמפרטורה, לחות ולחץ אטמוספרי', value: 5 },
      { text: 'טמפרטורה, לחות ואור', value: 6 },
      { text: 'אור', value: 7 },
      { text: 'תנועה', value: 8 },
      { text: 'גלאי עשן', value: 9 },
      { text: 'הצפה / נזילות', value: 10 },
      { text: 'מגנטי', value: 11 },
      { text: 'מגנטי - דלת', value: 12 },
      { text: 'מגנטי - חלון', value: 13 },
      { text: 'מגע יבש', value: 14 },
      { text: 'לחץ דיפרנציאלי', value: 15 },
      { text: 'מודד פעימות', value: 16 },
      { text: 'מודד פעימות - חשמל', value: 17 },
      { text: 'מודד פעימות - מים', value: 18 },
      { text: 'מד תאוצה', value: 19 },
      { text: 'מרחק', value: 20 },
    ],
  }),

  computed: {
    permitted () {
      return !!this.$store.getters['user/permissions'].edit_device_sensor_type
    },
  },

  watch: {
    model (value) {
      this.value = value
    },
    value (value) {
      this.$emit('edit', value)
    },
  },

  mounted () {
    this.value = this.model
  },
}
</script>
