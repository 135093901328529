<template>
  <div v-if="devmode">
    <h5>{{ multiple ? 'סף עליון ותחתון' : 'סף' }}</h5>
    <v-row class="flex-column mt-4">
      <base-smart-input
        v-if="devmode > 0 && !multiple"
        :model.sync="form.threshold"
        label="סף"
        required
        outlined
        :loading="loading"
        type="tel"
        autocomplete="off"
        persistent-placeholder
        background-color="white"
      />
      <div
        v-if="multiple"
        class="d-flex justify-space-between flex-nowrap align-baseline"
      >
        <label class="me-5">
          <v-icon color="secondary" class="me-2">
            mdi-format-vertical-align-top
          </v-icon>
          סף עליון:
        </label>
        <div>
          <base-smart-input
            :model.sync="form.top_threshold"
            required
            solo
            :loading="loading"
            type="tel"
            autocomplete="off"
            persistent-placeholder
            background-color="white"
          />
        </div>
      </div>
      <div
        v-if="multiple"
        class="d-flex justify-space-between flex-nowrap align-baseline"
      >
        <label class="me-5">
          <v-icon color="secondary" class="me-2">
            mdi-format-vertical-align-bottom
          </v-icon>
          סף תחתון:
        </label>
        <div>
          <base-smart-input
            :model.sync="form.bottom_threshold"
            required
            solo
            :loading="loading"
            type="tel"
            autocomplete="off"
            persistent-placeholder
            background-color="white"
          />
        </div>
      </div>
    </v-row>
    <!-- <v-row
      justify="space-between"
      class="align-center success--text text--darken-3"
    >
      <v-btn color="secondary" :loading="loading" @click="submit">
        שמירת {{ multiple ? 'סף עליון ותחתון' : 'סף' }}
      </v-btn>
      <h4 v-show="done">
        {{ !multiple ? 'הסף נערך' : 'הספים נערכו' }} בהצלחה!
      </h4>
    </v-row> -->
  </div>
</template>

<script>
export default {
  name: 'DeviceThresholds',
  props: {
    devmode: {
      type: [Number, String],
      default: null,
    },
    threshold: {
      type: [Number, String],
      default: null,
    },
    top_threshold: {
      type: [Number, String],
      default: null,
    },
    bottom_threshold: {
      type: [Number, String],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      threshold: null,
      top_threshold: null,
      bottom_threshold: null,
    },
    // loading: false,
    // done: false,
  }),

  computed: {
    device () {
      const device = this.$store.getters['devices/getBySlug'](
        this.$route.params.slug,
      )
      return device
    },
    // devmode () {
    //   return this.device.data.devmode
    // },
    multiple () {
      return this.devmode == 7
    },
    // submitable () {
    //   return (
    //     (this.edit.threshold != this.device.data.threshold ||
    //       this.edit.top_threshold != this.device.data.top_threshold ||
    //       this.edit.bottom_threshold != this.device.data.bottom_threshold) &&
    //     !isNaN(this.edit.threshold) &&
    //     !isNaN(this.edit.top_threshold) &&
    //     !isNaN(this.edit.bottom_threshold)
    //   )
    // },
  },
  watch: {
    threshold (value) {
      this.form.threshold = value
      // this.form.threshold = !isNaN(value) ? parseFloat(value) : value
    },
    top_threshold (value) {
      this.form.top_threshold = value
      // this.form.top_threshold = !isNaN(value) ? parseFloat(value) : value
    },
    bottom_threshold (value) {
      this.form.bottom_threshold = value
      // this.form.bottom_threshold = !isNaN(value) ? parseFloat(value) : value
    },
    form: {
      handler () {
        this.$emit('edit', this.form)
      },
      deep: true,
    },
  },
  mounted () {
    this.form.threshold = parseFloat(this.threshold)
    this.form.top_threshold = parseFloat(this.top_threshold)
    this.form.bottom_threshold = parseFloat(this.bottom_threshold)
    // this.value = this.model
  },

  // mounted () {
  //   this.setEditValue()
  // },
  // methods: {
  //   setEditValue () {
  //     this.edit = {
  //       threshold: this.device.data.threshold,
  //       top_threshold: this.device.data.top_threshold,
  //       bottom_threshold: this.device.data.bottom_threshold,
  //     }
  //   },
  //   submit () {
  //     console.log('submit')
  //     if (this.submitable) {
  //       console.log('send API')
  //       this.loading = true
  //       setTimeout(() => {
  //         this.loading = false
  //         this.succeed()
  //       }, 5000)
  //     }
  //   },
  //   succeed () {
  //     this.done = true
  //     setTimeout(() => {
  //       this.done = false
  //     }, 5000)
  //   },
  // },
}
</script>
