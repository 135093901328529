<template>
  <div
    id="switchCapsule"
    class="d-flex flex-nowrap justify-space-between align-center white px-4 py-2"
  >
    <label>
      <v-icon color="blue" class="me-2">
        mdi-message
      </v-icon>
      התראות SMS
    </label>
    <v-switch
      v-model="value"
      :true-value="1"
      :false-value="0"
      hide-details
      :loading="loading"
      class="mt-0"
      :disabled="loading"
    />
  </div>
</template>

<script>
export default {
  name: 'DeviceNotifySms',
  props: {
    model: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    value: null,
  }),

  watch: {
    model (value) {
      this.value = value
    },
    value (value) {
      this.$emit('edit', value)
    },
  },

  mounted () {
    this.value = this.model
  },
}
</script>
<style scoped>
#switchCapsule {
  border-radius: 10px;
  box-shadow: 2px 3px 5px #aaa;
}
</style>
